import { siteConfig } from '@/config/site.config'
import Link from 'next/link'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { getLangCode, I18nDictionary, useI18n } from '../i18n/lang-context'
import { Button } from '../ui/button'
import { Flex } from '../ui/flex'
import { CookieConsentLink } from './cookies-dialog'
import { Section } from './section'

const I18N: I18nDictionary = {
  copyright: { en: '© 2024 JBDA. All Rights Reserved.', fr: '© 2024 JBDA. Tous droits réservés.' },
  cgu: { en: 'T&Cs', fr: 'CGU' },
  legalMentions: { en: 'Legal', fr: 'Mentions légales' },
  privacyPolicy: { en: 'Privacy', fr: 'Confidentialité' }
}

export function Footer() {

  const i18n = useI18n(I18N)

  const LegalLink = ({ href, onClick, text }: { href?: string, onClick?: (e: React.MouseEvent) => void, text: string }) => (
    <Button variant='link' size='sm' className='text-xs text-muted-foreground hover:text-foreground'>
      {href && <Link href={href}>{text}</Link>}
      {!href && onClick && <div onClick={e => onClick(e)}>{text}</div>}
    </Button>
  )

  const SocialLink = ({ href, icon }: { href: string, icon: React.ReactNode }) => (
    <Button variant='ghost' className='aspect-square p-2 opacity-70 hover:opacity-100 hover:shadow-none'>
      <Link href={href} target='_blank' rel='noreferrer'>{icon}</Link>
    </Button>
  )

  return (
    <Section containerClassName='bg-background2'>
      <footer className='flex w-full flex-col items-center'>

        <Flex id='LEGAL_LINKS' className='flex w-fit justify-center gap-2'>
          <LegalLink href={'/' + getLangCode() + '/legal/legalMentions'} text={i18n.legalMentions} />
          <LegalLink href={'/' + getLangCode() + '/legal/privacyPolicy'} text={i18n.privacyPolicy} />
          <LegalLink href={'/' + getLangCode() + '/legal/cgu'} text={i18n.cgu} />
          <CookieConsentLink />
        </Flex>

        <Flex id='SOCIAL_LINKS' className='w-full items-center justify-center gap-2 text-xs text-muted-foreground'>
          <SocialLink href={siteConfig.links.linkedin} icon={<FaLinkedinIn />} />
          <SocialLink href={siteConfig.links.twitter} icon={<FaXTwitter />} />
        </Flex>

        <Flex id='COPYRIGHT' className='h-9 w-full items-center justify-center text-xs text-muted-foreground'>
          {i18n.copyright}
        </Flex>

      </footer>
    </Section>
  )
}
